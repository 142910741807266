import axios from 'axios';
import {
    Message
} from 'element-ui'
import store from "@/base/store";;
import {
    showLoading,
    hideLoading
} from "./loading"
import router from "@/globalRouter"

var service = axios.create({
    timeout: 300000,
    baseURL: "/qixianggongyingpingtai_api",
    headers: {
        'X-Custom-Header': 'foobar'
    }
});
// 添加请求拦截器(可以检查是否登录)
service.interceptors.request.use(
    config => {
        if (config.headers['hadLoading']) {

        } else {
            if (!config.noLoading) {
                showLoading()
            }
        }
        // console.log(config)
        //  给每次请求加一个时间戳
        if (config.method == 'get') {
            // console.log(config)
            if (!config.params) {
                config.params = {}
            } else {
                config.params['_t'] = new Date().getTime()
            }
        }
        // console.log(store.state.sessionId, 'token')
        //设置token
        config.headers.sessionId = sessionStorage.getItem('access_token') || ""
        return config;
    },
    error => {
        hideLoading();
        // 对请求错误做些什么
        return Promise.reject(error);
    });

// 添加响应拦截器
service.interceptors.response.use(
    response => {

        hideLoading();
        const data = response.data;
        if (data instanceof Blob) {
            return response
        } else if (data.code == -1) { //-1未登录，token失效
            localStorage.removeItem('access_token')
            store.commit("access_token", data.access_token)
            Message.closeAll()
            Message({
                message: data.msg || data.message || "系统出错",
                type: 'error',
                duration: 1000,
                onClose: function() {
                    router.push("/login");
                }
            });
            return data;
        } else if (data.code == -5) {
            Message.closeAll()
                // Message({
                //     message: "系统错误，联系开发人员",
                //     type: 'error',
                //     duration: 2000,
                // });
            // return data;
            return Message.error({message: data.msg})
        } else {
            // if (data.code != 1) {
            //     Message.error({
            //         message: data.msg
            //     })
            //     let err;
            //     throw err = new Error('服务器返回状态');
            // }
            return data;
        }
    },
    error => {
        hideLoading();
        Message.closeAll()
        Message({
            message: "网络超时,请稍后重试",
            type: 'error'
        });
        return Promise.reject(error)
    }
);

export default service;